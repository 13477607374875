import { Button, ButtonBase, styled } from '@mui/material';
import React, { useState } from 'react';
import { useAuth } from 'react-oidc-context';

function SSIDSettings() {
    const auth = useAuth()

    const [isEditing, setIsEditing] = useState(false);
    const [taxAgentNumber, setTaxAgentNumber] = useState('123 456 789');

    const [ emailSent, setEmailSent ] = useState(false)

    const TextButton = styled(Button)(({ theme }) => ({
        color: 'white',
        textTransform: 'none',
        padding: '0vw 0vw'
      }));

    function sendEmailHandler(){
        // openLoading("Sending email ...")
        fetch(`${window.origin}/api/sendemail`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        }).then(response => response.status
        ).then(status => {
            console.log(status)
        })
        setEmailSent(true)
    }

    function naviagteATOPortal(){
        window.open('https://www.ato.gov.au/General/Online-services/Access-Manager/About-Access-Manager/#LogintoAccessManager','_blank')
    }


    return (
        <div>
            <p style={{ fontSize: '0.8vw', color: 'rgb(255, 255, 255, 0.6)', fontWeight: '300' }}>
                Adjust your Tax Agent Number settings here.
            </p>
            
            <div 
                style={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    backgroundColor: 'rgb(255, 255, 255, 0.06)', 
                    padding: '1vw 1.5vw', 
                    borderRadius: '0.6vw', 
                    marginTop: '1.5vw', 
                    marginBottom: '1.5vw' 
                }}
            >
                <div style={{ flex: 1, fontWeight: '600', fontSize: '1vw' }}>
                    <p style={{ margin: 0 }}>Tax Agent Number</p>
                </div>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    {isEditing ? (
                        <input
                            type="text"
                            value={taxAgentNumber}
                            onChange={(e) => setTaxAgentNumber(e.target.value)}
                            style={{
                                padding: '0',
                                borderRadius: '1vw',
                                color: 'black',
                                backgroundColor: 'white',
                                border: '1px solid #ccc',
                                fontSize: '1vw',
                                flex: 1,
                            }}
                            placeholder="Enter Tax Agent Number"
                        />
                    ) : (
                        <p style={{ textAlign: 'right', color: 'rgb(255, 255, 255, 0.7)', margin: 0, fontSize: '1vw', flex: 1 }}>
                            {taxAgentNumber}
                        </p>
                    )}

                </div>
            </div>
            <p style={{ fontSize: '0.8vw', color: 'rgb(255, 255, 255, 0.6)', fontWeight: '300' }}>
                Configure and update your SSID.
            </p>
            
            <div 
                style={{ 
                    
                    backgroundColor: 'rgb(255, 255, 255, 0.06)', 
                    padding: '1.5vw 1.5vw', 
                    borderRadius: '0.6vw', 
                    marginTop: '1.5vw', 
                    marginBottom: '1.5vw' ,
                    // border: '1px rgb(255, 255, 255, 0.07) solid'
                }}
            >
                <p style={{marginTop: '0', fontSize: '1.2vw', marginBottom: '.8vh'}}>Step 1</p>
                <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0, marginBottom: '1vw'}}>Please click on Email button to receive your software service ID (SSID).</p>           
                <ButtonBase variant='text' style={{fontSize:'0.9vw', fontWeight: '600', cursor: 'pointer'}} onClick={sendEmailHandler}>{ !emailSent && 'Send Email'}{ emailSent && 'Resend Email'}</ButtonBase>
            </div>
            <div 
                style={{ 
                    backgroundColor: 'rgb(255, 255, 255, 0.06)', 
                    padding: '1.5vw 1.5vw', 
                    borderRadius: '0.6vw', 
                    marginTop: '1.5vw', 
                    marginBottom: '1.5vw',
                    // border: '1px rgb(255, 255, 255, 0.07) solid'
                }}
            >
                <p style={{marginTop: '0', fontSize: '1.2vw', marginBottom: '.8vh'}}>Step 2</p>
                <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0, marginBottom: '1vw'}}>Log in to Access Manager and update the SSID as provided to you via email.</p>           
                <ButtonBase variant='text' style={{fontSize:'0.9vw', fontWeight: '600', cursor: 'pointer'}} onClick={naviagteATOPortal}>Go to ATO Portal</ButtonBase>
            </div>
        </div>
    );
}

export default SSIDSettings;
